import "./Auth.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { authService, setRecoveryService } from "../services/account.service";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { getCurrentDateTime } from "../utils/DateTime";
import SuccessAnimation from "../assets/lotti/success.json";
import Face from "../assets/overflow/not_found_face.png"
import ContentContainer from "../components/ContentContainer";
import LoadingSymbol from "../components/LoadingSymbol";
import Button from "../components/Button";
import Lottie from "react-lottie";
import { changeBodyColor } from "../utils/Background";

function Auth() {
    const navigate = useNavigate();
    changeBodyColor("#fdfdfd");
    
    const { search } = useLocation();
    const params = new URLSearchParams(search);
    const token = params.get("token");
    const expires = params.get("expires");
    const type = params.get("type");

    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState("");
    const [success, setSuccess] = useState(false);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);

            if (type === "recovery") { //if recovery link
                const request = await setRecoveryService(token);
                const statusCode = getStatusCode(request);

                if (statusCode === 200) {
                    setSuccess(true);
                    setMessage("Wiederherstellung erfolgreich abgeschlossen.")
                } else {
                    processStatus(statusCode);
                };

            } else if (Number(expires) <= getCurrentDateTime() / 1000) { //if link expired
                setMessage("Der Link ist leider abgelaufen.");

            } else { //for all other cases
                const request = await authService(token);
                const statusCode = getStatusCode(request);
                processStatus(statusCode);
            };

            setLoading(false);
        };

        fetchData();
        // eslint-disable-next-line
    }, []);

    const animationOptions = {
        animationData: SuccessAnimation,
        loop: false,
        autoplay: true
    };

    return (
        <div className="w-s mt-5">
            <ContentContainer className="flex column items-center gap">
                {
                    loading ?
                        <>
                            <LoadingSymbol size={100} dark />
                            <h2 className="text-center">Bitte gedulde dich kurz...</h2>
                            <div className="flex start w-100">
                                <Link to="/qanda">Probleme mit der Verifikation?</Link>
                            </div>
                        </> :
                        <>
                            {
                                success ?
                                    <>
                                        <div className="lotti-animation">
                                            <Lottie options={animationOptions} isClickToPauseDisabled={true} />
                                        </div>
                                        <h2 className="text-center">{message}</h2>
                                        <Button title="Zum Dashboard" to="/dashboard" big fullWidth />
                                    </> :
                                    <>
                                        <img alt="face" src={Face} width="250px" />
                                        <h2 className="text-center">{message}</h2>
                                        <Button title="Erneut Versuchen" to="/login" big fullWidth />
                                    </>
                            }
                        </>
                }
            </ContentContainer>
        </div>
    );

    function processStatus(status: number) {
        switch (status) {
            case 200:
                navigate(`/dashboard`);
                break;
            case 400: navigate(`/errorpage?code=500`); break; //(request) input issues
            case 401: setMessage("Der Link wurde leider schon benutzt."); break; //unauthorized
            case 429: navigate("/errorpage?code=429"); break; //too many requests
            case 500: navigate("/errorpage?code=500"); break; //internal error on backend
            case 504: navigate("/errorpage?code=504"); break; //backend not reachable
            default: navigate("/errorpage?code=500"); break;
        };
    };
}

export default Auth;