import "./FAQ.css";
import Header from "../components/Header";
import TextExpandCollapse from "../components/TextExpandCollapse";

function FAQ() {
    return (
        <>
            <Header onlyTopBar buttonDefaultPage buttonTo="/login" buttonText="Anmelden" buttonClick={() => {}} />

            <div className="w-m flex column gap">
                <h1 className="title">FAQ</h1>
                <TextExpandCollapse title="Ist ein Benutzerkonto verpflichtend um die Anwendung nutzen zu können?" description="Ja." />
                <TextExpandCollapse title="Woher bekomme ich den Gamecode?" description="Einen Gamecode erhältst du, nachdem du das Spiel gestartet hast. Dieser wird in der Lobby auf der weißen Tafel angezeigt. Drücke auf die 'Neues Spiel'-Taste im Spiel, falls es Probleme mit dem Gamecode gibt." />
                <TextExpandCollapse title="Der Gamecode konnte nicht gefunden werden" description="Wenn der Gamecode nicht gefunden werden konnte, könnte es daran liegen, dass es Probleme mit der Verbindung gibt. Stelle sicher, dass du mit dem Internet verbunden bist und eine Verbindung zum Server herstellen kannst." />
                <TextExpandCollapse title="Welche VR-Brille eignet sich am besten?" description="Meta Quest 3" />
                <TextExpandCollapse title="Wie passe ich die Anzahl an Fehlversuchen an?" description="Durch Klicken auf die Herzen kannst du die Anzahl der möglichen Fehlversuche anpassen." />
            </div>
        </>
    );
};

export default FAQ;