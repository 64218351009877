import { useNavigate } from "react-router-dom";
import { getStatusCode } from "../utils/DBRequestProcessing";
import { getAccessToken, getHealthAmount, getRandomErrorAmount, setRandomErrorAmount } from "../utils/WebStorage";
import Button from "./Button";
import ContentContainer from "./ContentContainer";
import "./GameInterface.css";
import { useEffect, useState } from "react";
import { leaveGame } from "../pages/Dashboard";
import { editGameService, getGameService } from "../services/game.service";
import RunningGameInterface from "./RunningGameInterface";
import Header from "./Header";
import HeartBar from "./HeartBar";
import Input from "./Input";
import ErrorBox from "./ErrorBox";
import ArrowExpand from "../assets/overflow/arrow_expand.png";
import { errorContent } from "../utils/Data";
import GameEndBanner from "./GameEndBanner";
import { useIsMobile } from "../utils/Mobile";
import WarningModal from "./WarningModal";

function GameInterface(
    props: Readonly<{
        setJoined: (joined: boolean) => void;
    }>
) {
    const { setJoined } = props;

    const navigate = useNavigate();
    const isMobile = useIsMobile();

    const [errorMessage, setErrorMessage] = useState("");

    const [choosenErrors, setChoosenErrors] = useState([]);

    const [selected, setSelected] = useState(false); //true = random numbers, false = individual errors

    const [gameStatus, setGameStatus] = useState(null);
    const [gameCode, setGameCode] = useState("Lädt...");
    const [deviceUUID, setdeviceUUID] = useState("Lädt...");
    const [maximumAmountError, setMaximumAmountError] = useState(0);

    const [mistakeCoordinates, setMistakeCoordinates] = useState([]);
    const [errorCoordinates, setErrorCoordinates] = useState([]);
    const [gameErrors, setGameErrors] = useState([]);
    const [foundErrors, setFoundErrors] = useState([]);

    const [loading, setLoading] = useState(false);

    const randomErrorAmount = getRandomErrorAmount();
    if (randomErrorAmount === null) {
        setRandomErrorAmount(5); //default
    }

    const [amountNumber, setAmountNumber] = useState(randomErrorAmount); //default
    function handleNumberchange(event: any) {
        const userInput = event.target.value;
        try {
            if (userInput === "" || ((Number(userInput) > -1 && Number(userInput) < 100) && userInput.length < 4)) {
                setAmountNumber(userInput);
            }
        } catch (error) {
            setAmountNumber(userInput.slice(0, -1));
            setAmountNumber(userInput);
        }
    }

    useEffect(() => {
        setRandomErrorAmount(amountNumber);
    }, [amountNumber]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            await getGameData();
            setLoading(false);
        }

        fetchData();

        const interval = setInterval(async () => {
            await getGameData();
        }, 1000);

        return () => {
            clearInterval(interval);
        };
        // eslint-disable-next-line
    }, [])

    const [readableGameStatus, setReadableGameStatus] = useState("");
    useEffect(() => {
        switch(gameStatus) {
            case "LOBBY": setReadableGameStatus("Einrichtung"); break;
            case "SETUP": setReadableGameStatus("Einrichtung"); break;
            case "STARTED": setReadableGameStatus("Gestartet"); break;
            case "ENDED": setReadableGameStatus("Beendet"); break;
            default: setReadableGameStatus("FEHLER"); break;
        }
    }, [gameStatus])

    function handleError(number: number) { //on error select (individual error selection)
        if (choosenErrors.includes(number)) {
            setChoosenErrors(prevErrors => prevErrors.filter(error => error !== number)); //remove number from error arraylist
        } else {
            setChoosenErrors(prevErrors => [...prevErrors, number]) //add number to array list
        }
    }

    async function handleStart() { //on start press
        setErrorMessage("");

        if (selected) { //individual errors
            if (choosenErrors.length === 0) {
                return setErrorMessage("Bitte wähle mindestens einen individuellen Fehler");
            }

            await changeGameErrors(choosenErrors);
        } else { //random errors
            if (Number(amountNumber) - 1 > maximumAmountError) {
                return setErrorMessage(`Die maximale Anzahl an Fehlern wurde überschritten (maximal ${maximumAmountError + 1} stück)`);
            } else if (Number(amountNumber) <= 0) {
                return setErrorMessage("Es muss mindestens ein Fehler bestehen");
            }

            let randomErrors = [];
            for (let i = 0; i < Number(amountNumber); i++) {
                let randomNumber: number;
                do {
                    randomNumber = Math.floor(Math.random() * (maximumAmountError + 1));
                } while (randomErrors.includes(randomNumber));
                randomErrors.push(randomNumber);
            }

            await changeGameErrors(randomErrors);
        }

        //further config before game start
        await changeGameMistakeAmount(getHealthAmount()); //change mistake amount

        changeGameStatus("STARTED");
    }

    const [leaveGameModal, setLeaveGameModal] = useState(false);
    
    return (
        <>
            {leaveGameModal && <WarningModal title="Möchtest du das Spiel wirklich verlassen?" option1="Ja" option2="Nein"
            choosenOption={(choise: boolean) => {
                if (choise) {
                    leaveGame(setJoined, navigate)
                }

                setLeaveGameModal(false);
            }} />}
            
            <Header
                title={`Spiel ${gameCode}<br>${readableGameStatus}`}
                buttonClick={() => setLeaveGameModal(true)}
                buttonText="Verlassen"
                subtitle={deviceUUID}
                settings
                bigLogo
            />

            <GameEndBanner className={`game-end-banner-animation ${gameStatus !== "ENDED" && "invisibile"}`} won={foundErrors.length === gameErrors.length && gameStatus === "ENDED"} newGameClick={() => {changeGameStatus("SETUP")}} />

            <div className="game-interface">
                <ContentContainer className="flex column gap">
                    {/* []====================[ CONTENT ]====================[] */}
                    {gameStatus === "STARTED" || gameStatus === "ENDED" ?
                        <RunningGameInterface mistakeCoordinates={mistakeCoordinates} mistakeAmount={getHealthAmount()} errorCoordinates={errorCoordinates} gameErrors={gameErrors} foundErrors={foundErrors} />
                        :
                        <div className={`settings-container ${loading ? "disabled" : ""}`}>
                            <div className="flex wrap between w-100 gap items-center">
                                <p className="subtitle">Fehlversuche</p>
                                <HeartBar totalAmount={10} />
                            </div>

                            <div className="break-line" /> {/* ------------------------------------------------ */}

                            <div
                                className={`flex wrap between w-100 gap items-center ${selected && "disable"}`}
                                onClick={() => {
                                    if (selected) {
                                        setSelected(!selected);
                                    }
                                }}
                            >
                                <p className="subtitle">Anzahl zufälliger Fehler (max. {maximumAmountError + 1})</p>
                                <Input
                                    onChange={handleNumberchange}
                                    value={amountNumber}
                                    type="numeric"
                                    onFocus={(e: any) => {e.target.value = ""; setAmountNumber(null)}}
                                />
                            </div>

                            <div className="break-line" /> {/* ------------------------------------------------ */}

                            <div className="expand-individual-errors" onClick={() => setSelected(!selected)}>
                                <p className="subtitle">Individuelle Fehler</p>
                                <img src={ArrowExpand} className={`${selected && "active"}`} alt="expand arrow" width={18} height={18} />
                            </div>
                            
                            <div className={`individual-container ${selected && "expanded"}`}>
                                {Array.from({ length: errorContent.length }, (value: any, index: number) => (
                                    <ErrorBox key={index} clicked={() => handleError(index)} selected={choosenErrors.includes(index)} title={errorContent[index][0] as string} image={errorContent[index][1]} />
                                ))}

                            </div>
                            
                            
                        </div>
                    }

                    <p className="error">{errorMessage}</p>

                    {/* []====================[ BUTTONS ]====================[] */}
                    <div className="w-100 flex items-center center">
                        {gameStatus === "STARTED" ?
                            <Button title="SPIEL ABBRECHEN" big loading={loading} onClick={() => {changeGameStatus("SETUP")}} />
                            :
                            <>
                                {gameStatus !== "ENDED" && <Button className="flex-1" title="SPIEL STARTEN" big loading={loading} onClick={() => handleStart()} />}
                            </>
                        }
                    </div>
                </ContentContainer>
            </div>
        </>
    );

    async function changeGameErrors(errors: any) {
        setLoading(true);

        const request = await editGameService(getAccessToken(), "errors", `[${errors}]`);
        const statusCode = getStatusCode(request);

        switch (statusCode) {
            case 200: break; //success
            case 504: navigate("/errorpage?code=504"); break; //backend not reachable
            default: navigate("/errorpage?code=500"); break;
        };

        setLoading(false);
    };

    async function changeGameMistakeAmount(amount: number) {
        setLoading(true);

        const request = await editGameService(getAccessToken(), "mistakes", amount);
        const statusCode = getStatusCode(request);

        switch (statusCode) {
            case 200: break; //success
            case 504: navigate("/errorpage?code=504"); break; //backend not reachable
            default: navigate("/errorpage?code=500"); break;
        };

        setLoading(false);
    };

    async function changeGameStatus(status: string) {
        setLoading(true);

        const request = await editGameService(getAccessToken(), "status", status);
        const statusCode = getStatusCode(request);

        setTimeout(() => { //to await game api request
            switch (statusCode) {
                case 200: break; //success
                case 504: navigate("/errorpage?code=504"); break; //backend not reachable
                default: navigate("/errorpage?code=500"); break;
            };

            if (isMobile && status === "SETUP") { //if "spiel abbrechen"
                window.scrollTo(0, 0);
            };

            setLoading(false);
        }, 1000); //timeout intervall - game loading
    };

    async function getGameData() {
        const request = await getGameService(getAccessToken());
        const statusCode = getStatusCode(request);

        switch (statusCode) {
            case 200:
                setGameStatus(request.data.gameStatus);
                setGameCode(request.data.gameCode);
                setdeviceUUID(request.data.deviceUUID);
                setMaximumAmountError(Number(request.data.maximumAmountErrors));
                setMistakeCoordinates(request.data.gameMistakeCoordinates);
                setErrorCoordinates(request.data.gameErrorCoordinates);
                setGameErrors(request.data.gameErrors);
                setFoundErrors(request.data.gameFoundErrors);
                break; //success
            case 504: navigate("/errorpage?code=504"); break; //backend not reachable
            default: break;
        };
    }
};

export default GameInterface;